<template>
  <div class="g-section">
    <div class="g-wrap flex-column">
      <div class="form-header">
        <!-- <span>发布职位</span> -->
        <!-- <el-button type="primary" plain size="small">返回</el-button> -->
      </div>
      <div class="from-content">
        <order-detail></order-detail>
      </div>
    </div>
  </div>
</template>

<script>
import OrderDetail from './components/OrderDetail'
export default {
  components:{OrderDetail}
}
</script>
<style lang="scss" scoped>
.form-header{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
</style>