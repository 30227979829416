<template>
  <div>
    <el-row :gutter="15">
      <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
        <el-col :span="12">
          <el-form-item label="订单名称" prop="Name">
            <el-input v-model="formData.Name" placeholder="请输入订单名称" clearable :style="{width: '100%'}">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="需求企业" prop="EnterpriseId">
            <el-input v-model="formData.EnterpriseId" placeholder="请输入需求企业" clearable
              :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="订单说明" prop="Content">
            <el-input v-model="formData.Content" type="textarea" placeholder="请输入订单说明"
              :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="相关协议" prop="AgreementFileId" required>
            <el-upload ref="AgreementFileId" :file-list="AgreementFileIdfileList"
              :action="AgreementFileIdAction" :before-upload="AgreementFileIdBeforeUpload">
              <el-button size="small" type="primary" icon="el-icon-upload">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item size="large">
            <el-button type="primary" @click="submitForm">保存</el-button>
            <el-button @click="resetForm">发布</el-button>
            
            <p>注：可按步骤条发布：如：1、订单信息，2、岗位信息，3、确认订单，4、发布成功</p>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      formData: {
        Name: undefined,
        EnterpriseId: undefined,
        Content: undefined,
        AgreementFileId: null,
      },
      rules: {
        Name: [{
          required: true,
          message: '请输入订单名称',
          trigger: 'blur'
        }],
        EnterpriseId: [],
        Content: [{
          required: true,
          message: '请输入订单说明',
          trigger: 'blur'
        }],
      },
      AgreementFileIdAction: 'https://jsonplaceholder.typicode.com/posts/',
      AgreementFileIdfileList: [],
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        // TODO 提交表单
      })
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
    },
    AgreementFileIdBeforeUpload(file) {
      let isRightSize = file.size / 1024 / 1024 < 2
      if (!isRightSize) {
        this.$message.error('文件大小超过 2MB')
      }
      return isRightSize
    },
  }
}

</script>
<style>
.el-upload__tip {
  line-height: 1.2;
}

</style>
