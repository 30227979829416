<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="订单信息" name="first">
        <order-form> </order-form>
      </el-tab-pane>
      <el-tab-pane label="岗位需求" name="second">

        <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="类型" prop="JobType">
                <el-select v-model="formData.JobType" placeholder="请选择类型" clearable :style="{width: '100%'}">
                  <el-option v-for="(item, index) in JobTypeOptions" :key="index" :label="item.label" :value="item.value" :disabled="item.disabled">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职位类型" prop="JobCategoryId">
                <el-cascader v-model="formData.JobCategoryId" :options="JobCategoryIdOptions" :props="JobCategoryIdProps" :style="{width: '100%'}"
                  placeholder="请选择职位类型" clearable></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职位名称" prop="Name">
                <el-input v-model="formData.Name" placeholder="请输入职位名称" clearable :style="{width: '100%'}">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="招聘人数" prop="HiringCount">
                <el-input v-model="formData.HiringCount" placeholder="请输入招聘人数" clearable :style="{width: '100%'}">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="学历要求" prop="Education">
                <el-select v-model="formData.Education" placeholder="请选择学历要求" clearable :style="{width: '100%'}">
                  <el-option v-for="(item, index) in EducationOptions" :key="index" :label="item.label" :value="item.value" :disabled="item.disabled">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工作地点" prop="AreaId">
                <el-select v-model="formData.AreaId" placeholder="请选择工作地点" clearable :style="{width: '100%'}">
                  <el-option v-for="(item, index) in AreaIdOptions" :key="index" :label="item.label" :value="item.value" :disabled="item.disabled">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="薪资范围" prop="MinSalary">
                <el-input v-model="formData.MinSalary" placeholder="请输入最低薪资" clearable :style="{width: '100%'}">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="至" prop="MaxSalary">
                <el-input v-model="formData.MaxSalary" placeholder="请输入最高薪资" clearable :style="{width: '100%'}">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="岗位职责" prop="JobDescription">
                <el-input v-model="formData.JobDescription" type="textarea" placeholder="请输入岗位职责" :autosize="{minRows: 4, maxRows: 4}"
                  :style="{width: '100%'}"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="任职要求" prop="JobRequirements">
                <el-input v-model="formData.JobRequirements" type="textarea" placeholder="请输入任职要求" :autosize="{minRows: 4, maxRows: 4}"
                  :style="{width: '100%'}"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="福利待遇" prop="Benefits">
                <el-input v-model="formData.Benefits" placeholder="请输入福利待遇" clearable :style="{width: '100%'}">
                </el-input>
              </el-form-item>
            </el-col>

          </el-row>
        </el-form>
        <div class="wrap-btns"> 
            <el-button type="primary" size="small" @click="submitForm">添加岗位</el-button> 
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import OrderForm from './OrderForm'
export default {
  components: { OrderForm },
  props: [],
  data() {
    return {
      activeName: 'first',
      formData: {
        JobType: undefined,
        JobCategoryId: [1, 2],
        Name: undefined,
        HiringCount: undefined,
        Experience: undefined,
        Education: undefined,
        MinSalary: undefined,
        MaxSalary: undefined,
        JobDescription: undefined,
        JobRequirements: undefined,
        Keyword: undefined,
        Benefits: undefined,
        AreaId: undefined,
        Department: undefined,
      },
      rules: {
        JobType: [{
          required: true,
          message: '请选择招聘类型',
          trigger: 'change'
        }],
        JobCategoryId: [{
          required: true,
          type: 'array',
          message: '请至少选择一个职位类型',
          trigger: 'change'
        }],
        Name: [{
          required: true,
          message: '请输入职位名称',
          trigger: 'blur'
        }],
        HiringCount: [{
          required: true,
          message: '请输入招聘人数',
          trigger: 'blur'
        }],
        Experience: [{
          required: true,
          message: '请选择工作经验',
          trigger: 'change'
        }],
        Education: [{
          required: true,
          message: '请选择学历要求',
          trigger: 'change'
        }],
        MinSalary: [{
          required: true,
          message: '请输入最低薪资',
          trigger: 'blur'
        }],
        MaxSalary: [{
          required: true,
          message: '请输入最高薪资',
          trigger: 'blur'
        }],
        JobDescription: [{
          required: true,
          message: '请输入岗位职责',
          trigger: 'blur'
        }],
        JobRequirements: [{
          required: true,
          message: '请输入任职要求',
          trigger: 'blur'
        }],
        Keyword: [],
        Benefits: [],
        AreaId: [{
          required: true,
          message: '请选择工作地点',
          trigger: 'change'
        }],
        Department: [{
          required: true,
          message: '请选择所属部门',
          trigger: 'change'
        }],
      },
      JobTypeOptions: [{
        "label": "全职",
        "value": 1
      }, {
        "label": "兼职",
        "value": 2
      }, {
        "label": "实习生",
        "value": 3
      }],
      JobCategoryIdOptions: [],
      ExperienceOptions: [{
        "label": "选项一",
        "value": 1
      }, {
        "label": "选项二",
        "value": 2
      }],
      EducationOptions: [{
        "label": "选项一",
        "value": 1
      }, {
        "label": "选项二",
        "value": 2
      }],
      AreaIdOptions: [{
        "label": "选项一",
        "value": 1
      }, {
        "label": "选项二",
        "value": 2
      }],
      DepartmentOptions: [{
        "label": "选项一",
        "value": 1
      }, {
        "label": "选项二",
        "value": 2
      }],
      JobCategoryIdProps: {
        "multiple": false,
        "label": "label",
        "value": "value",
        "children": "children"
      },
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getJobCategoryIdOptions()
  },
  mounted() { },
  methods: {
    handleClick(e) {

    },
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        // TODO 提交表单
      })
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
    },
    getJobCategoryIdOptions() {
      // 注意：this.$axios是通过Vue.prototype.$axios = axios挂载产生的
      // this.$axios({
      //   method: 'get',
      //   url: 'https://www.fastmock.site/mock/f8d7a54fb1e60561e2f720d5a810009d/fg/cascaderList'
      // }).then(resp => {
      //   var {
      //     data
      //   } = resp
      //   this.JobCategoryIdOptions = data.list
      // })
    },
    handleCancel() {
      this.$router.push("/enterprise/job")
    }
  }
}

</script>
<style lang="scss" scoped>
.wrap-btns{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
</style>
